import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/main.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./constants/query-client";
import { TelegramProvider } from "./providers/TelegramProvider";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TonConnectUIProvider manifestUrl="https://bobster.freeblock.site/tonconnect-manifest.json">
        <TelegramProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </TelegramProvider>
      </TonConnectUIProvider>
    </BrowserRouter>
  </React.StrictMode>
);
