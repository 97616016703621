import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";
import { INewOrderForm, IOrder } from "../models/order.service";

const createOrder = async (data: INewOrderForm) => {
  return authApi
    .post("/orders", {
      ...data,
    })
    .then((res) => res.data as IOrder)
    .catch((error) => {
      throw new Error(error);
    });
};

const openOrderDispute = async (orderId: string) => {
  return authApi
    .patch("/orders/dispute", {
      orderId,
    })
    .then((res) => res.data);
};

export const useOpenOrderDispute = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["OrderDispute"],
    mutationFn: (orderId: string) => openOrderDispute(orderId),
    onSuccess,
    onError,
  });
};

const getOrder = async (orderId: string) => {
  return authApi
    .patch(`/orders/byOrderId`, {
      orderId,
    })
    .then((res) => res.data as IOrder)
    .catch((error) => {
      throw new Error(error);
    });
};

const getOrderHistory = async (customerId: number) => {
  return authApi
    .get(`/orders/${customerId}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const clientSendMoney = async (data: { orderId: string }) => {
  return authApi
    .patch("/orders/customerSent", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const confirmTransaction = async (data: { orderId: string }) => {
  return authApi
    .patch("/orders/customerConfirm", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const leaveReview = async (data: {
  orderId: string;
  traderId: number;
  customerId: number;
  type: string;
  score: number;
  content: string;
}) => {
  return authApi
    .post("/reviews", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useCreateOrder = (
  onSuccess?: (data: IOrder) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["CreateOrder"],
    mutationFn: (data: INewOrderForm) => createOrder(data),
    onSuccess,
    onError,
  });
};

export const useGetOrderHistory = (customerId: number) => {
  const {
    data: orders,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["OrderHistory", customerId],
    queryFn: () => getOrderHistory(customerId),
    enabled: !!customerId,
  });

  return { orders, isLoading, isError };
};

export const useGetOrder = (orderId: string) => {
  const {
    data: order,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["Order"],
    queryFn: () => getOrder(orderId),
    refetchInterval: 30000,
  });

  return {
    order,
    isLoading,
    isError,
    refetch,
  };
};

export const useClientSendMoney = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["ClientSendFunds"],
    mutationFn: (data: { orderId: string }) => clientSendMoney(data),
    onSuccess,
    onError,
  });
};

export const useConfirmTransaction = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["ConfirmTransaction"],
    mutationFn: (data: { orderId: string }) => confirmTransaction(data),
    onSuccess,
    onError,
  });
};

export const useLeaveReview = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["LeaveReview"],
    mutationFn: (data: {
      orderId: string;
      traderId: number;
      customerId: number;
      type: string;
      score: number;
      content: string;
    }) => leaveReview(data),
    onSuccess,
    onError,
  });
};
