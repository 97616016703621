import React, { useState, useEffect } from "react";
import { ICurrency } from "../../../models/currencies.services";

interface IProps {
  id: string;
  selectedCurrency: ICurrency | null;
  onCurrencyChange: (item: ICurrency) => void;
  currencies: ICurrency[];
  label: string;
  rightText?: string;
}

const Dropdown: React.FC<IProps> = ({
  id,
  selectedCurrency,
  onCurrencyChange,
  currencies,
  label,
  rightText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = (e: any) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!selectedCurrency && currencies?.length > 0) {
      onCurrencyChange(currencies[0]);
    }
  }, [selectedCurrency, currencies, onCurrencyChange]);

  const handleCurrencySelect = (currency: ICurrency) => {
    onCurrencyChange(currency);
    setIsOpen(false);
  };

  return (
    <div className="form__item">
      <div className="form__item-row">
        <label htmlFor={id} className="form__label">
          {label}
        </label>
        {rightText && <div className="form__converter">{rightText}</div>}
      </div>
      <button
        className="form__control form-control form-control--transfer"
        onClick={toggleDropdown}
      >
        <div className="form-control__img form-control__img--currency">
          <p className="form-control__icon">{selectedCurrency?.symbol}</p>
        </div>
        <p className="form-control__text">
          {selectedCurrency?.code}{" "}
          <span className="form-control__text-currency">
            ({selectedCurrency?.name})
          </span>
        </p>
        <img
          className="form-control__arrow"
          src="/img/svg/arrow.svg"
          alt="arrow"
        />
      </button>

      {isOpen && (
        <ul className="dropdown-menu">
          {currencies.map((currency) => (
            <li
              key={currency.code}
              className="dropdown-item"
              onClick={() => handleCurrencySelect(currency)}
            >
              <div className="form-control__img form-control__img--currency">
                <p className="form-control__icon">{currency.symbol}</p>
              </div>
              <p className="form-control__text-dropdown">
                {currency.code}{" "}
                <span className="form-control__text-currency">
                  ({currency.name})
                </span>
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
