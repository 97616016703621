export const ROUTES = {
  MAIN: "/",
  ORDER_CHAT: "/:orderId/chat",
  SETTINGS: "/settings",
  SETTING_CARDS: "/settings/cards",
  NEW_CARD: "/requisites/add-card",
  NOTIFICATIONS: "/notifications",
  HISTORY: "/history",
  SUPPORT: "/support",
  SUPPORT_CHAT: "/support/:id",
  REQUISITES: "/requisites",
  NEW_IBAN: "/requisites/new-iban",
  ORDER_PENDING: "/order-pending",
};
