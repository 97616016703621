import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetNotifications } from "../../services/notifications.services";
import { useWebSocket } from "../../contexts/WebSocketContext";

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const { notifications: notificationList } = useGetNotifications(page, 10);

  const { subscribe, unsubscribe } = useWebSocket(); // Используем useWebSocket

  // Подписка на входящие уведомления
  useEffect(() => {
    const handleNotification = (notification: any) => {
      console.log("Received notification:", notification); // Логируем входящее уведомление
      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);
    };

    // Подписываемся на событие "notification"
    subscribe("notification", handleNotification);

    return () => {
      // Отписываемся при размонтировании компонента
      unsubscribe("notification", handleNotification);
    };
  }, [subscribe, unsubscribe]);

  // Обновление списка уведомлений при получении новых данных
  useEffect(() => {
    if (notificationList) {
      setNotifications((prevNotifications) => [
        ...notificationList,
        ...prevNotifications,
      ]);
    }
  }, [notificationList]);

  // Загрузка следующих уведомлений
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="main notification">
      <div className="body">
        <h1 className="notification__title title mb-title">
          {t("NOTIFICATIONS.TITLE")}
        </h1>

        {notifications.length === 0 ? (
          <p className="notification__empty-list">
            {t("NOTIFICATIONS.EMPTY_LIST")}
          </p>
        ) : (
          <ul className="notification__items">
            {notifications.map((notification: any, index: number) => (
              <li key={index} className="notification__item">
                <a href="#" className="notification__link">
                  <div className="notification__img">
                    <svg className="notification__icon">
                      <use xlinkHref="/img/sprite.svg#document"></use>
                    </svg>
                  </div>
                  <div className="notification__body">
                    <div className="notification__date">
                      {notification.date}
                    </div>
                    <div className="notification__descr">
                      <div className="notification__order">
                        #{notification.order}
                      </div>
                      <p className="notification__text">
                        <span className="notification__author">
                          {notification.author}:
                        </span>{" "}
                        {notification.text}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      {notifications.length > 0 && (
        <div className="footer">
          <button className="button button--gray-border" onClick={loadMore}>
            {t("LOAD_MORE")}
          </button>
        </div>
      )}
    </div>
  );
};

export default Notifications;
