import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getMe = async () => {
  return authApi
    .get("/auth/me")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const updateUser = async (data: {
  myCurrencyId?: number;
  languageId: number;
  receiverCurrencyId?: number;
}) => {
  return authApi
    .post("/auth/me/setting", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetMe = () => {
  const {
    data: me,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["Me"],
    queryFn: getMe,
  });

  return {
    me,
    isLoading,
    isError,
  };
};

export const useUpdateUser = (
  onSuccess?: () => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdateUser"],
    mutationFn: (data: {
      myCurrencyId?: number;
      languageId: number;
      receiverCurrencyId?: number;
    }) => updateUser(data),
    onSuccess,
    onError,
  });
};
