import React, { useEffect, useState } from "react";
import RoutesWrapper from "./routes/RoutesWrapper";
import { useTelegram } from "./providers/TelegramProvider";
import { useAuth } from "./hooks/useAuth";
import {
  useGetLanguage,
  useGetLanguageFile,
} from "./services/languages.services";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import Loading from "./components/Loading";
import { useGetLanguages } from "./services/settings.service";
import { useGetMe } from "./services/user.services";
import { WebSocketProvider } from "./providers/WebSocketProvider";
import { Toaster } from "react-hot-toast";

const App: React.FC = () => {
  const [language, setLanguage] = useState<number>();
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const [loadingItems, setLoadingItems] = useState<string[]>([]);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const { me } = useGetMe();

  const { webApp } = useTelegram();

  const { languages, isLoading: isLanguagesLoading } = useGetLanguages();
  const { language: currentLanguage, isLoading: isLanguageLoading } =
    useGetLanguage(language?.toString()!);
  const { languageFile, isLoading: isLanguageFileLoading } = useGetLanguageFile(
    currentLanguage?.file_path
  );

  const tgId = webApp?.initDataUnsafe?.user?.id?.toString() || "";
  const userName = webApp?.initDataUnsafe?.user?.username || "";
  const referralCode =
    new URLSearchParams(window.location.search).get("ref") || "";

  const { isAuthenticated, isAuthCompleted, token } = useAuth(
    tgId,
    userName,
    referralCode
  );

  // Проверка и обновление языка, если он отличается от сохраненного в localStorage
  useEffect(() => {
    if (currentLanguage && currentLanguage.id !== language) {
      setLanguage(currentLanguage.id);
      localStorage.setItem("LANGUAGE", currentLanguage.id.toString());
    }
  }, [currentLanguage, language]);

  // Установка языка по умолчанию, если в localStorage нет сохраненного языка
  useEffect(() => {
    if (!localStorage.getItem("LANGUAGE") && languages?.length > 0) {
      const fallbackLanguage = languages[0];
      setLanguage(fallbackLanguage.id);
      localStorage.setItem("LANGUAGE", fallbackLanguage.id.toString());
    } else {
      setLanguage(+(localStorage.getItem("LANGUAGE") || 0));
    }
  }, [languages]);

  // Сравнение языка из me.language.id с localStorage и обновление при необходимости
  useEffect(() => {
    if (me?.language?.id && isAuthenticated) {
      const storedLanguageId = +(localStorage.getItem("LANGUAGE") || 0);
      if (storedLanguageId !== me.language.id) {
        setLanguage(me.language.id);
        localStorage.setItem("LANGUAGE", me.language.id.toString());
      }
    }
  }, [me, isAuthenticated]);

  // Загрузка языкового файла и обновление i18n
  useEffect(() => {
    if (languageFile && currentLanguage) {
      i18n.addResourceBundle(
        currentLanguage.code,
        "translation",
        languageFile,
        true,
        true
      );
      i18n.changeLanguage(currentLanguage.code);
      setIsLanguageLoaded(true);
    } else if (!isLanguageFileLoading && !languageFile) {
      console.error(
        "Language file not found. Falling back to default language."
      );
      localStorage.removeItem("LANGUAGE");
      if (languages?.length > 0) {
        const fallbackLanguage = languages[0];
        setLanguage(fallbackLanguage.id);
        localStorage.setItem("LANGUAGE", fallbackLanguage.id.toString());
      }
    }
  }, [languageFile, currentLanguage, isLanguageFileLoading, languages]);

  // Обновление списка загружаемых элементов
  useEffect(() => {
    const loadingList: string[] = [];
    if (isLanguagesLoading) loadingList.push("Languages");
    if (isLanguageLoading) loadingList.push("Current Language");
    if (isLanguageFileLoading) loadingList.push("Language File");
    setLoadingItems(loadingList);
  }, [isLanguagesLoading, isLanguageLoading, isLanguageFileLoading]);

  // Завершение загрузки аутентификации
  useEffect(() => {
    if (isAuthCompleted) {
      setIsAuthLoading(false);
    }
  }, [isAuthCompleted]);

  const isLoading =
    isLanguagesLoading ||
    isLanguageLoading ||
    isLanguageFileLoading ||
    isAuthLoading;

  if (!isAuthCompleted) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <Loading loadingItems={loadingItems} isAuthLoading={isAuthLoading} />
    );
  }

  if (isLoading || !isLanguageLoaded) {
    return (
      <Loading loadingItems={loadingItems} isAuthLoading={isAuthLoading} />
    );
  }

  return (
    <WebSocketProvider
      url="https://bobster.freeblock.site/websocket"
      token={token}
    >
      <I18nextProvider i18n={i18n}>
        <RoutesWrapper />
        <Toaster position="top-center" reverseOrder={false} />
      </I18nextProvider>
    </WebSocketProvider>
  );
};

export default App;
