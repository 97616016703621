import React, { useState } from "react";
import { IPaymentMethod } from "../../../models/paymentMethod.model";
import { useTranslation } from "react-i18next";
import { TFunction } from "../../../models/translation";

interface IProps {
  paymentMethods: IPaymentMethod[];
  selectedPaymentMethod: IPaymentMethod | null; // Изменено на nullable
  onMethodChange: (method: IPaymentMethod) => void;
}

const PaymentMethodDropdown: React.FC<IProps> = ({
  paymentMethods,
  selectedPaymentMethod,
  onMethodChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const paymentMethodDetails = selectedPaymentMethod
    ? JSON.parse(selectedPaymentMethod.details)
    : {};

  const toggleDropdown = (e: any) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const handleMethodSelect = (method: IPaymentMethod) => {
    onMethodChange(method);
    setIsOpen(false);
  };

  return (
    <div className="form__item-payment-method">
      <button
        onClick={toggleDropdown}
        className="client-main__form-card form-control form-control--transfer"
      >
        {selectedPaymentMethod?.type === "CreditCard" &&
          paymentMethodDetails?.Type && (
            <img
              className="form-control__img"
              src={`/img/card-icons/${paymentMethodDetails.Type}.svg`}
              alt={paymentMethodDetails.Type}
            />
          )}
        <p className="form-control__text">
          {selectedPaymentMethod?.type === "CreditCard" &&
          paymentMethodDetails?.Number
            ? t("CUSTOMER.PAYMENT_METHOD.CARD", {
                number: `**** ${paymentMethodDetails.Number.slice(
                  paymentMethodDetails.Number.length - 4
                )}`,
              })
            : selectedPaymentMethod?.type === "IBAN" &&
              paymentMethodDetails?.Number
            ? t("CUSTOMER.PAYMENT_METHOD.IBAN", {
                number: `**** ${paymentMethodDetails.Number.slice(
                  paymentMethodDetails.Number.length - 6
                )}`,
              })
            : t("SELECT_PAYMENT_METHOD")}{" "}
        </p>
        <img
          className="form-control__arrow"
          src="/img/svg/arrow.svg"
          alt="arrow"
        />
      </button>

      {isOpen && (
        <ul className="dropdown__payment-method-menu">
          {paymentMethods.map((method) => (
            <li
              key={method.id}
              className="dropdown-item"
              onClick={() => handleMethodSelect(method)}
            >
              {method.type === "CreditCard" &&
                JSON.parse(method.details)?.Type && (
                  <img
                    className="form-control-method__img"
                    src={`/img/card-icons/${
                      JSON.parse(method.details)?.Type
                    }.svg`}
                    alt={JSON.parse(method.details)?.Type}
                  />
                )}
              <p className="form-control__text">
                {method.type === "CreditCard"
                  ? t("CUSTOMER.PAYMENT_METHOD.CARD", {
                      number: `**** ${JSON.parse(method.details)?.Number?.slice(
                        JSON.parse(method.details)?.Number?.length - 4
                      )}`,
                    })
                  : t("CUSTOMER.PAYMENT_METHOD.IBAN", {
                      number: `**** ${JSON.parse(method.details)?.Number?.slice(
                        JSON.parse(method.details)?.Number?.length - 6
                      )}`,
                    })}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PaymentMethodDropdown;
