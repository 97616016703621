import { useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

// Функция для получения уведомлений с пагинацией
const getNotifications = async (page: number, limit: number = 10) => {
  return authApi
    .get(`/notifications?page=${page}&limit=${limit}`, {
      baseURL: "https://bobster.freeblock.site/api/v1",
    })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

// Хук для получения уведомлений с учетом пагинации
export const useGetNotifications = (page: number, limit: number = 10) => {
  const {
    data: notifications,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["Notifications", page], // Обновляем queryKey с учетом страницы
    queryFn: () => getNotifications(page, limit),
  });

  return {
    notifications,
    isLoading,
    isError,
    refetch,
  };
};
