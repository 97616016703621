import React, { useState, useEffect, useRef, useCallback } from "react";
import { useWebSocket } from "../../contexts/WebSocketContext";
import { useGetMe } from "../../services/user.services";
import {
  useCloseSupportChat,
  useGetMessagesFromSupportChat,
  useReadMessage,
} from "../../services/chat.services";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { TFunction } from "../../models/translation";
import { useTranslation } from "react-i18next";

interface Message {
  id: number;
  text: string;
  sender: "user" | "support";
  time: string;
  date: string;
  read?: boolean;
}

const Support: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [ticketId, setTicketId] = useState<string>("");
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const { me } = useGetMe();
  const { t } = useTranslation();
  const { messageList, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetMessagesFromSupportChat(ticketId, currentPage);
  const { mutate: readMessage } = useReadMessage(
    () => {},
    () => {}
  );
  const { subscribe, unsubscribe, emit } = useWebSocket();
  const navigate = useNavigate();

  // Прокрутка к последнему сообщению
  const scrollToBottom = useCallback(
    (smooth: boolean = true) => {
      if (messagesEndRef.current && autoScrollEnabled) {
        messagesEndRef.current.scrollIntoView({
          behavior: smooth ? "smooth" : "auto",
        });
      }
    },
    [autoScrollEnabled]
  );

  // Закрытие чата
  const { mutate: closeSupportChat } = useCloseSupportChat(() => {
    localStorage.removeItem("chatId");
    setTicketId("");
    navigate(-1);
    toast.success(t("CHAT_CLOSED"));
  });

  // Установка ID чата
  useEffect(() => {
    let currentChatId = localStorage.getItem("chatId");
    if (!currentChatId) {
      currentChatId = uuidv4();
      localStorage.setItem("chatId", currentChatId!);
    }
    setTicketId(currentChatId!);
  }, []);

  // Загрузка сообщений
  useEffect(() => {
    if (messageList && messageList.length > 0 && me) {
      const loadedMessages = messageList.map((msg: any) => ({
        id: msg.chatMessage_id,
        text: msg.chatMessage_message,
        sender: msg.chatMessage_senderId === me?.id ? "user" : "support",
        time: new Date(msg.chatMessage_createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: msg.chatMessage_createdAt.split("T")[0],
        read: msg.chatMessage_read,
      }));

      if (messages.length === 0) {
        setMessages(loadedMessages as any);
        markMessagesAsRead();
        scrollToBottom(false);
      }
    }
  }, [messageList, me, messages.length, scrollToBottom]);

  // Подписка на событие "message"
  useEffect(() => {
    const handleMessage = (message: any) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: message.id,
          text: message.message,
          sender: message.senderId === me?.id ? "user" : "support",
          time: new Date(message.createdAt).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          date: message.createdAt.split("T")[0],
          read: message.read,
        },
      ]);
      if (autoScrollEnabled) scrollToBottom();
    };

    subscribe("message", handleMessage);

    return () => {
      unsubscribe("message", handleMessage);
    };
  }, [subscribe, unsubscribe, me, autoScrollEnabled, scrollToBottom]);

  // Отправка сообщения
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (inputValue.trim() && me) {
        const newMessage: Message = {
          id: Date.now(),
          text: inputValue,
          sender: "user",
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          date: new Date().toISOString().split("T")[0],
          read: false,
        };

        emit("sendMessageToSupport", {
          senderId: me.id,
          receiverId: "support",
          ticketId: ticketId,
          message: inputValue,
        });

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setInputValue("");
      }
    },
    [inputValue, emit, me, ticketId]
  );

  // Отправка сообщения по нажатию на Enter
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit]
  );

  // Закрытие чата
  const handleEndChat = useCallback(() => {
    closeSupportChat(ticketId);
  }, [closeSupportChat, ticketId]);

  // Группировка сообщений по дате
  const groupedMessages = messages.reduce<{ [date: string]: Message[] }>(
    (groups, message) => {
      const date = message.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    },
    {}
  );

  // Пометить сообщения как прочитанные
  const markMessagesAsRead = useCallback(() => {
    const unreadMessages = messages.filter(
      (message) => message.sender === "support" && !message.read
    );

    unreadMessages.forEach((message) => {
      readMessage(message.id.toString());
    });

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.sender === "support" && !msg.read ? { ...msg, read: true } : msg
      )
    );
  }, [messages, readMessage]);

  // Обработчик прокрутки
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement;

      if (target.scrollTop === 0 && hasNextPage && !isFetchingNextPage) {
        setCurrentPage((prevPage) => prevPage + 1);
        fetchNextPage();
      }

      const isAtBottom =
        target.scrollHeight - target.scrollTop - target.clientHeight < 50;

      setAutoScrollEnabled(isAtBottom);
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage]
  );

  return (
    <div
      className="main chat"
      style={{
        paddingBottom: messages.length > 0 ? 30 : "1.25rem",
      }}
    >
      <div className="body" style={{ marginBottom: 0 }}>
        <h1 className="chat__title title">{t("SUPPORT_CHAT")}</h1>
        <div
          className="chat__items"
          ref={chatContainerRef}
          onScroll={handleScroll}
        >
          {Object.keys(groupedMessages).map((date) => (
            <div key={date}>
              <div className="chat__date">{date}</div>

              {groupedMessages[date].map((message) => (
                <div key={message.id} className="chat__messages">
                  <div
                    className={`chat__message chat__message--${message.sender}`}
                  >
                    <div className="chat__message-text">{message.text}</div>
                    <div className="chat__message-time">
                      {message.time}
                      {message.sender === "user" && (
                        <div className="chat__message-check">
                          <img
                            className={`chat__message-check-icon ${
                              message.read ? "read" : ""
                            }`}
                            src="/img/svg/check-mes.svg"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        <form className="chat__input-container" onSubmit={handleSubmit}>
          <button type="button" className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--emoji"
              src="/img/svg/smile.svg"
              alt=""
            />
          </button>
          <textarea
            className="chat__input"
            placeholder={t("FORM.MESSAGE.PLACEHOLDER")}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            rows={1}
          />
          <button type="button" className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--mention"
              src="/img/svg/mention.svg"
              alt=""
            />
          </button>
          <button type="submit" className="chat__input-btn">
            <svg className="chat__input-icon chat__input-icon--send">
              <use xlinkHref="/img/sprite.svg#send"></use>
            </svg>
          </button>
        </form>

        {messages.length > 0 && (
          <div className="footer chat__actions">
            <button
              className="chat__button chat__button--reject button button--gray-border"
              onClick={handleEndChat}
            >
              {t("ISSUE_IS_NOT_RESOLVED")}
            </button>
            <button className="chat__button button" onClick={handleEndChat}>
              {t("ISSUE_RESOLVED")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Support;
