import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGetOrder } from "../../services/order.services";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetMessagesFromSupportChat,
  useReadMessage,
} from "../../services/chat.services";
import { useTelegram } from "../../providers/TelegramProvider";
import { ROUTES } from "../../routes/routes";
import { useGetMe } from "../../services/user.services";
import { TFunction } from "../../models/translation";
import { useWebSocket } from "../../contexts/WebSocketContext";

interface Message {
  id: number;
  text: string;
  sender: "user" | "support";
  time: string;
  date: string;
  read?: boolean;
}

const OrderChat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const { orderId } = useParams<{ orderId: string }>();
  const { order } = useGetOrder(orderId!);
  const { me } = useGetMe();
  const { messageList } = useGetMessagesFromSupportChat(orderId!, 1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { webApp } = useTelegram();
  const { mutate: readMessage } = useReadMessage(
    () => {},
    () => {}
  );

  const { subscribe, unsubscribe, emit } = useWebSocket();

  // Функция для пометки сообщений как прочитанных
  const markMessagesAsRead = useCallback(() => {
    const unreadMessages = messages.filter(
      (message) => message.sender === "support" && !message.read
    );

    if (unreadMessages.length > 0) {
      unreadMessages.forEach((message) => {
        readMessage(message.id.toString());
      });

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.sender === "support" && !msg.read ? { ...msg, read: true } : msg
        )
      );
    }
  }, [messages, readMessage]);

  // Обработчик скролла
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      markMessagesAsRead();
    }
  };

  // Обработчик отправки сообщения
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (inputValue.trim() && me && order) {
      const newMessage: Message = {
        id: Date.now(),
        text: inputValue,
        sender: "user",
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: new Date().toISOString().split("T")[0],
        read: false,
      };

      // Отправляем сообщение через WebSocket
      emit("sendMessage", {
        senderId: me.id,
        receiverId: order.trader1.id,
        orderId: orderId,
        message: inputValue,
      });

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue("");
    }
  };

  // Обработчик нажатия клавиш
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  // Подписка на входящие сообщения
  useEffect(() => {
    const handleMessage = (message: any) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: message.id,
          text: message.message,
          sender: message.senderId === me?.id ? "user" : "support",
          time: new Date(message.createdAt).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          date: message.createdAt.split("T")[0],
          read: message.read,
        },
      ]);
    };

    subscribe("message", handleMessage);

    return () => {
      unsubscribe("message", handleMessage);
    };
  }, [me, subscribe, unsubscribe]);

  // Обработчик кнопки "Назад" в Telegram Mini App
  useEffect(() => {
    if (!webApp) return;

    const handleBackButtonClick = () => {
      navigate(ROUTES.MAIN);
    };

    webApp.BackButton.onClick(handleBackButtonClick);
    webApp.BackButton.show();

    return () => {
      webApp.BackButton.offClick(handleBackButtonClick);
      webApp.BackButton.hide();
    };
  }, [webApp, navigate]);

  // Загрузка сообщений при изменении messageList
  useEffect(() => {
    if (messageList && messageList.length > 0 && me) {
      const loadedMessages = messageList.map((msg: any) => ({
        id: msg.chatMessage_id,
        text: msg.chatMessage_message,
        sender: msg.chatMessage_senderId === me.id ? "user" : "support",
        time: new Date(msg.chatMessage_createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: msg.chatMessage_createdAt.split("T")[0],
        read: msg.chatMessage_read,
      }));

      if (JSON.stringify(loadedMessages) !== JSON.stringify(messages)) {
        setMessages(loadedMessages as any);
        markMessagesAsRead();
      }
    } else if (messages.length === 0) {
      setMessages([]);
    }
  }, []);

  // Группировка сообщений по дате
  const groupedMessages = messages.reduce<{ [date: string]: Message[] }>(
    (groups, message) => {
      const date = message.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    },
    {}
  );

  return (
    <div className="main chat">
      <div className="body">
        <h1 className="chat__title title">
          {t("ORDER_CHAT.HEADER", {
            orderId,
          })}
        </h1>
        <div className="chat__items" onScroll={handleScroll}>
          {Object.keys(groupedMessages).map((date) => (
            <div key={date}>
              <div className="chat__date">{date}</div>
              {groupedMessages[date].map((message) => (
                <div key={message.id} className="chat__messages">
                  <div
                    className={`chat__message chat__message--${message.sender}`}
                  >
                    <div className="chat__message-text">{message.text}</div>
                    <div className="chat__message-time">
                      {message.time}
                      {message.sender === "user" && (
                        <div className="chat__message-check">
                          <img
                            className={`chat__message-check-icon ${
                              message.read ? "read" : ""
                            }`}
                            src="/img/svg/check-mes.svg"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <form className="chat__input-container" onSubmit={handleSubmit}>
          <button type="button" className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--emoji"
              src="/img/svg/smile.svg"
              alt=""
            />
          </button>
          <textarea
            className="chat__input"
            placeholder="Start typing..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown} // Добавляем обработчик
          ></textarea>
          <button type="button" className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--mention"
              src="/img/svg/mention.svg"
              alt=""
            />
          </button>
          <button type="submit" className="chat__input-btn">
            <svg className="chat__input-icon chat__input-icon--send">
              <use xlinkHref="/img/sprite.svg#send"></use>
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default OrderChat;
