import React, { useEffect, useState } from "react";
import { useGetOrder } from "../../services/order.services";
import { useTranslation } from "react-i18next";
import { TFunction } from "../../models/translation";

const OrderPending: React.FC = () => {
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const { order, refetch } = useGetOrder(orderId!);
  const { t } = useTranslation();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Запускаем интервал для повторного запроса каждые 15 секунд
    const id = setInterval(() => {
      refetch();
    }, 15000); // 15000 миллисекунд = 15 секунд

    setIntervalId(id);

    // Очищаем интервал при размонтировании компонента
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [refetch]);

  useEffect(() => {
    if (order?.status === 2) {
      localStorage.setItem("ORDER_STATUS", "TRADER_FOUND");
      // Останавливаем интервал, если статус ордера изменился на 2
      if (intervalId) clearInterval(intervalId);
    }
  }, [order, intervalId]);

  return (
    <div className="main client-main-load">
      <div className="body">
        <h1 className="client-main-load__title title">{t("WAITING")}</h1>
        <div className="client-main-load__body">
          <img className="client-main-load__logo" src="./img/logo.svg"></img>
          <div className="client-main-load__progress progress-load">
            <div className="progress-load__item progress-completed">
              <div className="progress-load__item-circle">
                <div className="progress-load__item-icon"></div>
                <p className="progress-load__item-text">
                  {t("TRANSACTION_CREATING")}
                </p>
              </div>
              <div className="progress-load__item-line"></div>
            </div>

            <div className="progress-load__item progress-active">
              <div className="progress-load__item-circle">
                <div className="progress-load__item-icon"></div>
                <p className="progress-load__item-text">
                  {t("PREPARE_INFORMATION")}
                </p>
              </div>
              <div className="progress-load__item-line"></div>
            </div>

            <div className="progress-load__item">
              <div className="progress-load__item-circle">
                <div className="progress-load__item-icon"></div>
                <p className="progress-load__item-text">
                  {t("CONFIRM_RECEIVER")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <button className="button disable">{t("WAIT_A_LITTLE")}</button>
      </div>
    </div>
  );
};

export default OrderPending;
