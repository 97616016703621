import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useGetExchangePairs } from "../../services/exchangePairs.services";
import { useGetPaymentMethods } from "../../services/paymentMethod.services";
import { useCreateOrder } from "../../services/order.services";
import { useGetMe } from "../../services/user.services";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Dropdown from "../../components/Form/Dropdown";
import PaymentMethodDropdown from "../../components/Form/PaymentMethodDropdown";
import { ROUTES } from "../../routes/routes";
import { TFunction } from "../../models/translation";
import { INewOrderForm } from "../../models/order.service";
import { IPaymentMethod } from "../../models/paymentMethod.model";

// Схема валидации формы
const OrderFormSchema = yup.object({
  customerCurrencyId: yup.number().required("FORM.REQUIRED.CURRENCY"),
  receiverCurrencyId: yup.number().required("FORM.REQUIRED.CURRENCY"),
  customerId: yup.number().required(),
  amount: yup.number().required("FORM.REQUIRED.AMOUNT_OF_TRANSFER"),
  paymentMethodId: yup.number().optional(),
  orderType: yup.number().required(),
});

const Main: React.FC = () => {
  const { t } = useTranslation();
  const { me } = useGetMe();
  const navigate = useNavigate();

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    IPaymentMethod[] | null
  >(null);

  const { exchangePairs } = useGetExchangePairs();
  const { paymentMethods } = useGetPaymentMethods();
  const { mutate: createOrder } = useCreateOrder(
    (data) => {
      localStorage.setItem("ORDER_STATUS", "PENDING");
      localStorage.setItem("CURRENT_ORDER_ID", data.orderId.toString());
      navigateToNextScreen(data.orderType);
    },
    (error) => toast.error(error)
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(OrderFormSchema),
    defaultValues: {
      orderType: 1,
    },
  });

  const customerCurrencyId = watch("customerCurrencyId");
  const receiverCurrencyId = watch("receiverCurrencyId");
  const amount = watch("amount");
  const orderType = watch("orderType");

  // Обработка выбора оплаты наличными
  const navigateToNextScreen = (orderType: number) => {
    if (orderType === 2) {
      // Логика для направления на другие экраны при оплате наличными
      console.log("Оплата наличными выбрана. Направляем на другой экран...");
      // Здесь будет логика навигации
    } else {
      navigate(ROUTES.ORDER_PENDING);
    }
  };

  const processedExchangePairs = useMemo(() => {
    if (!exchangePairs) return [];

    return exchangePairs.reduce((acc: any[], pair: any) => {
      // Добавляем оригинальную пару
      acc.push(pair);

      // Добавляем обратную пару с инверсией курса
      acc.push({
        baseCurrencyId: pair.targetCurrencyId,
        targetCurrencyId: pair.baseCurrencyId,
        exchangeRate: 1 / pair.exchangeRate,
      });

      return acc;
    }, []);
  }, [exchangePairs]);

  // Уникальные валюты для дропдауна "В"
  const filteredCurrencies = useMemo(() => {
    if (!processedExchangePairs || !customerCurrencyId) return [];

    // Фильтруем пары, где базовая валюта совпадает с выбранной
    const pairs = processedExchangePairs.filter(
      (pair: any) => pair.baseCurrencyId.id === customerCurrencyId
    );

    // Собираем уникальные целевые валюты
    const uniqueCurrencies = new Map();
    pairs.forEach((pair: any) => {
      if (!uniqueCurrencies.has(pair.targetCurrencyId.id)) {
        uniqueCurrencies.set(pair.targetCurrencyId.id, pair.targetCurrencyId);
      }
    });

    return Array.from(uniqueCurrencies.values());
  }, [processedExchangePairs, customerCurrencyId]);

  // Курс обмена
  const exchangeRate = useMemo(() => {
    const selectedPair = processedExchangePairs?.find(
      (pair: any) =>
        pair.baseCurrencyId.id === customerCurrencyId &&
        pair.targetCurrencyId.id === receiverCurrencyId
    );
    return selectedPair?.exchangeRate || 0;
  }, [processedExchangePairs, customerCurrencyId, receiverCurrencyId]);

  // Доступные валюты
  const availableCurrencies = useMemo(() => {
    const currencyIds = new Set();
    return (
      processedExchangePairs?.reduce((currencies: any[], pair: any) => {
        if (!currencyIds.has(pair.baseCurrencyId.id)) {
          currencies.push(pair.baseCurrencyId);
          currencyIds.add(pair.baseCurrencyId.id);
        }
        if (!currencyIds.has(pair.targetCurrencyId.id)) {
          currencies.push(pair.targetCurrencyId);
          currencyIds.add(pair.targetCurrencyId.id);
        }
        return currencies;
      }, []) || []
    );
  }, [processedExchangePairs]);

  // Установка начальных значений валют
  useEffect(() => {
    if (me && availableCurrencies?.length) {
      setValue("customerCurrencyId", availableCurrencies[0]?.id);
      setValue("receiverCurrencyId", availableCurrencies[1]?.id);
      setValue("customerId", me.id);
    }
  }, [availableCurrencies, me, setValue]);

  // Установка начального метода оплаты
  useEffect(() => {
    if (paymentMethods?.length) {
      setValue("paymentMethodId", paymentMethods[0]?.id);
    }
  }, [paymentMethods, setValue]);

  // Фильтрация доступных методов оплаты
  useEffect(() => {
    if (receiverCurrencyId && paymentMethods) {
      setAvailablePaymentMethods(
        paymentMethods.filter(
          (item) =>
            JSON.parse(item.details)?.Currency ===
            availableCurrencies.find(
              (currency: any) => currency.id === receiverCurrencyId
            )?.code
        )
      );
    }
  }, [receiverCurrencyId, paymentMethods, availableCurrencies]);

  // Рассчитанная сумма получения
  const receivedAmount = amount && exchangeRate ? amount * exchangeRate : 0;

  // Отправка формы
  const submitOrderForm = (data: INewOrderForm) => {
    if (!paymentMethods?.length && !data.paymentMethodId) {
      navigate(ROUTES.REQUISITES);
    } else {
      createOrder({ ...data, orderType: data.orderType });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitOrderForm)} className="main client-main">
      <div className="body">
        <Link to={ROUTES.SUPPORT} className="help">
          <img className="help__icon" src="/img/svg/help.svg" alt="Help" />
          <p className="help__text">{t("HELP")}</p>
        </Link>
        <h1 className="client-main__title title">{t("SEND_TRANSFER")}</h1>
        <div className="client-main__form form">
          <div className="client-main__form-items form__items">
            <Controller
              name="customerCurrencyId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="customerCurrencyId"
                  currencies={availableCurrencies.filter(
                    (currency: { id: number }) =>
                      currency.id !== receiverCurrencyId
                  )}
                  selectedCurrency={
                    availableCurrencies.find(
                      (currency: { id: number }) => currency?.id === value
                    )!
                  }
                  onCurrencyChange={(item) => onChange(item?.id)}
                  label={t("YOU_SEND")}
                />
              )}
            />
            <div className="form__item">
              <Controller
                name="amount"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <input
                      className="form-control form-control--transfer"
                      type="number"
                      placeholder={t("FORM.PLACEHOLDER.AMOUNT_OF_TRANSFER")}
                      value={value || ""}
                      onChange={onChange}
                    />
                    {errors.amount?.message && (
                      <p className="error">{errors.amount?.message}</p>
                    )}
                  </>
                )}
              />
              <div className="form__item-bottom">
                {t("LIMIT")}{" "}
                {availableCurrencies.find(
                  (item: { id: number }) => item.id === customerCurrencyId
                )?.limit || 0}{" "}
                {availableCurrencies.find(
                  (item: { id: number }) => item.id === customerCurrencyId
                )?.code || "USD"}
              </div>
            </div>
            <Controller
              name="receiverCurrencyId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="receiverCurrencyId"
                  currencies={filteredCurrencies}
                  selectedCurrency={
                    filteredCurrencies.find(
                      (currency: { id: number }) => currency?.id === value
                    )!
                  }
                  onCurrencyChange={(item) => onChange(item?.id)}
                  label={t("YOU_RECEIVE")}
                  rightText={`${t("RATE")} ${
                    availableCurrencies?.find(
                      (item: { id: number }) => item.id === customerCurrencyId
                    )?.code
                  } = ${Number(exchangeRate).toFixed(2)} ${
                    availableCurrencies?.find(
                      (item: { id: number }) => item.id === receiverCurrencyId
                    )?.code
                  }`}
                />
              )}
            />
          </div>

          <div className="client-main__form-received">
            {receivedAmount.toFixed(2)}{" "}
            {availableCurrencies.find(
              (item: { id: number }) => item.id === receiverCurrencyId
            )?.code || "USD"}
          </div>

          <div className="client-main__form-options options">
            <Controller
              name="orderType"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    {["CARD", "CASH"]?.map((type, index) => (
                      <div className="options__item" key={index}>
                        <input
                          id={type.toLowerCase()}
                          type="radio"
                          name="payment"
                          className="options__input"
                          onChange={() => onChange(type === "CARD" ? 1 : 2)}
                          checked={value === (type === "CARD" ? 1 : 2)}
                        />
                        <label
                          htmlFor={type.toLowerCase()}
                          className="options__label"
                        >
                          {t(type)}
                        </label>
                      </div>
                    ))}
                  </>
                );
              }}
            />
          </div>

          {availablePaymentMethods?.length! > 0 && orderType === 1 && (
            <Controller
              name="paymentMethodId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <PaymentMethodDropdown
                  paymentMethods={availablePaymentMethods!}
                  onMethodChange={(value) => onChange(value.id)}
                  selectedPaymentMethod={
                    availablePaymentMethods?.find(
                      (method: { id: number | undefined }) =>
                        method.id === value
                    )!
                  }
                />
              )}
            />
          )}

          {orderType === 1 && (
            <Link
              to={ROUTES.REQUISITES}
              className="client-main__form-requisites"
            >
              {t("ADD_REQUISITES")}
            </Link>
          )}
        </div>
      </div>
      <div className="footer">
        <button type="submit" className="button">
          {orderType === 1 ? t("NAVBAR.TRANSFER") : t("CREATE_TRANSFER")}
        </button>
      </div>
    </form>
  );
};

export default Main;
