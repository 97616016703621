import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import { useWebSocket } from "../../contexts/WebSocketContext";

const Header: React.FC = () => {
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const { subscribe, unsubscribe } = useWebSocket(); // Используем useWebSocket

  // Подписка на входящие уведомления
  useEffect(() => {
    const handleNotification = (notification: any) => {
      console.log("Received notification:", notification); // Логируем входящее уведомление
      setHasNewNotifications(true); // Устанавливаем флаг, что есть новые уведомления
    };

    // Подписываемся на событие "notification"
    subscribe("notification", handleNotification);

    return () => {
      // Отписываемся при размонтировании компонента
      unsubscribe("notification", handleNotification);
    };
  }, [subscribe, unsubscribe]);

  return (
    <header className="header">
      <div className="header__row">
        <Link to={ROUTES.MAIN} className="header__action">
          <svg className="header__action-icon">
            <use xlinkHref="/img/sprite.svg#person"></use>
          </svg>
        </Link>
        <Link to={ROUTES.MAIN} className="header__logo">
          <div className="logo">
            <img
              className="logo__icon"
              src="/img/logo.svg"
              alt="logo bobster"
            />
            <div className="logo__text">
              <div className="logo__name">BOBSTER</div>
              <div className="logo__descr">PAYMENTS</div>
            </div>
          </div>
        </Link>
        <Link to={ROUTES.NOTIFICATIONS} className="header__action">
          <div className="header__notification-icon">
            <svg className="header__action-icon">
              <use xlinkHref="/img/sprite.svg#notification"></use>
            </svg>
            {hasNewNotifications && (
              <span className="header__notification-dot"></span>
            )}
          </div>
        </Link>
      </div>
    </header>
  );
};

export default Header;
