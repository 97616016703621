import React, { useState } from "react";
import { useGetOrderHistory } from "../../services/order.services";
import { useGetMe } from "../../services/user.services";
import moment from "moment";
import { TFunction } from "../../models/translation";
import { useTranslation } from "react-i18next";

const History: React.FC = () => {
  const { me } = useGetMe();
  const { orders } = useGetOrderHistory(me?.id);
  const { t } = useTranslation();

  const [onlyOpen, setOnlyOpen] = useState(false);

  const handleCheckboxChange = () => {
    setOnlyOpen(!onlyOpen);
  };

  const filteredOrders = onlyOpen
    ? orders?.filter((order: any) => order.status === 1)
    : orders;

  return (
    <div className="main transactions">
      <div className="body">
        <h1 className="transactions__title title mb-title">
          {t("NAVBAR.HISTORY")}
        </h1>
        <div className="transactions__checkbox checkbox">
          <input
            id="open"
            type="checkbox"
            name="open"
            className="checkbox__input"
            checked={onlyOpen}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="open" className="checkbox__label">
            {t("OPEN_ONLY")}
          </label>
        </div>
        <div className="transactions__items">
          {filteredOrders?.map((order: any) => (
            <a
              href="#"
              className="transactions__item item-transactions item-transactions--history"
              key={order.orderId}
            >
              <div className="item-transactions__data item-transactions__data--order">
                #{order?.orderId}
              </div>
              <div className="item-transactions__data">
                {moment(order?.updatedAt).format("DD.MM.YYYY hh:mm")}
              </div>
              <div className="item-transactions__data item-transactions__data--amount">
                {order.amount} {order.customerCurrency.code}
              </div>
              <div className="item-transactions__data">
                {(order.status < 8 || order.status === 10) && (
                  <div className="item-transactions__status item-transactions__status--open">
                    <img
                      className="item-transactions__status-icon"
                      src="/img/svg/reload.svg"
                      alt=""
                    />
                  </div>
                )}
                {order.status === 9 && (
                  <div className="item-transactions__status item-transactions__status--done">
                    <img
                      className="item-transactions__status-icon"
                      src="/img/svg/check-chat.svg"
                      alt=""
                    />
                  </div>
                )}
                {order.status === 8 && (
                  <div className="item-transactions__status item-transactions__status--reject">
                    <img
                      className="item-transactions__status-icon"
                      src="/img/svg/close.svg"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="footer">
        {/* <button onClick={handleLoadMore} className="button button--gray-border">
          Загрузить еще
        </button> */}
      </div>
    </div>
  );
};

export default History;
