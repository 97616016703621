import React, { useEffect } from "react";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetOrder, useLeaveReview } from "../../services/order.services";
import { TFunction } from "../../models/translation";
import { useTranslation } from "react-i18next";

const OrderCompleted: React.FC = () => {
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const { order } = useGetOrder(orderId!);
  const { t } = useTranslation();

  const { mutate: leaveReview } = useLeaveReview(
    () => {
      toast.success(t("REVIEW_LEAVED"));
      localStorage.removeItem("CURRENT_ORDER_ID");
      localStorage.removeItem("ORDER_STATUS");
    },
    () => {
      toast.error(t("SOMETHING_WENT_WRONG"));
    }
  );

  const ReviewFormSchema = yup.object().shape({
    orderId: yup.string().required(t("FORM.ORDER_ID.REQUIRED")),
    customerId: yup.number().required(t("FORM.CUSTOMER_ID.REQUIRED")),
    traderId: yup.number().required(t("FORM.TRADER_ID.REQUIRED")),
    type: yup.string().required(t("FORM.ORDER_TYPE.REQUIRED")),
    score: yup.number().required(t("FORM.SCORE.REQUIRED")).min(1).max(5),
    content: yup.string().required(t("FORM.COMMEND.REQUIRED")).max(500),
  });

  const { register, handleSubmit, watch, formState, setValue } = useForm({
    resolver: yupResolver(ReviewFormSchema),
    defaultValues: {
      orderId: orderId!,
      customerId: order?.customer.id!,
      traderId: order?.trader1?.id!,
      type: "Fast",
      score: 3,
      content: "",
    },
  });

  const { errors } = formState;

  const contentLength = watch("content")?.length || 0;

  const onSubmitReviewForm = (data: any) => {
    leaveReview(data);
  };

  useEffect(() => {
    if (order) {
      setValue("orderId", order.orderId);
      setValue("customerId", order.customer.id);
      setValue("traderId", order.trader1.id);
    }
  }, [order]);

  return (
    <form
      className="main deal-done"
      onSubmit={handleSubmit(onSubmitReviewForm)}
    >
      <div className="body">
        <div className="deal-done__done done">
          <img className="done__icon" src="/img/svg/done.svg" alt="success" />
          <p className="done__text">
            {t("ORDER_IS_OVER", {
              orderId: order?.orderId,
            })}
          </p>
        </div>
        <div className="deal-done__rating rating">
          <h2 className="deal-done__rating-title">{t("LEAVE_REVIEW")}</h2>
          <div className="rating__items">
            {[5, 4, 3, 2, 1].map((score) => (
              <React.Fragment key={score}>
                <input
                  className="rating__item"
                  type="radio"
                  {...register("score")}
                  value={score}
                  id={`score-${score}`}
                />
                <label className="rating__label" htmlFor={`score-${score}`} />
              </React.Fragment>
            ))}
          </div>
          {errors.score && <p className="error">{errors.score.message}</p>}
        </div>
        <div className="deal-done__review review">
          <h2 className="deal-done__rating-title">
            {t("FORM.ORDER_TYPE.LABEL")}
          </h2>
          <div className="review__types">
            {[
              { value: "Fast", label: t("FORM.REVIEW.FAST_OPTION") },
              { value: "Safety", label: t("FORM.REVIEW.SAFETY_OPTION") },
              { value: "GoodPrice", label: t("FORM.REVIEW.GOOD_PRICE_OPTION") },
            ].map((type) => (
              <React.Fragment key={type.value}>
                <input
                  className="review__item"
                  type="radio"
                  {...register("type")}
                  value={type.value}
                  id={`type-${type.value}`}
                />
                <label className="review__label" htmlFor={`type-${type.value}`}>
                  {type.label}
                </label>
              </React.Fragment>
            ))}
          </div>
          {errors.type && <p className="error">{errors.type.message}</p>}
        </div>
        <div className="deal-done__feedback feedback">
          <textarea
            {...register("content")}
            name="content"
            className="feedback__textarea"
            placeholder={t("FORM.COMMEND.PLACEHOLDER")}
          />
          <div className="feedback__count">{contentLength}/500</div>
          {errors.content && <p className="error">{errors.content.message}</p>}
        </div>
      </div>
      <div className="footer">
        <button type="submit" className="button">
          {t("SEND.REVIEW")}
        </button>
      </div>
    </form>
  );
};

export default OrderCompleted;
