import { useEffect, useState } from "react";

export const useCountdownTimer = (endTime: number | null) => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    if (!endTime) return;

    const updateTimer = () => {
      const currentTime = Date.now();
      const remainingTime = endTime - currentTime;

      if (remainingTime <= 0) {
        setTimeLeft(0);
      } else {
        setTimeLeft(remainingTime);
      }
    };

    const intervalId = setInterval(updateTimer, 1000);
    updateTimer(); // Вызываем сразу, чтобы избежать задержки в 1 секунду

    return () => clearInterval(intervalId);
  }, [endTime]);

  return timeLeft;
};
