import React, { useEffect, useState } from "react";
import { useClientSendMoney, useGetOrder } from "../../services/order.services";
import toast from "react-hot-toast";
import { useGetExchangeRate } from "../../services/exchangePairs.services";
import { useTranslation } from "react-i18next";
import { TFunction } from "../../models/translation";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import { useGetPaymentMethodById } from "../../services/paymentMethod.services";
import { useCountdownTimer } from "../../hooks/useCountdownTimer";

const SendOrderAmount: React.FC = () => {
  const { t } = useTranslation();
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const { order } = useGetOrder(orderId!);
  const { exchangeRate } = useGetExchangeRate(
    order?.customerCurrency.id.toString()!,
    order?.receiverCurrency.id.toString()!
  );
  const { paymentMethod } = useGetPaymentMethodById({
    paymentMethodId: order?.traderPaymentMethodId.toString()!,
    userId: order?.trader1.id.toString()!,
  });

  const warningSound = new Audio("/sounds/warning-sound.mp3");

  const { mutate: clientSentMoney } = useClientSendMoney(
    () => {
      localStorage.setItem("ORDER_STATUS", "CUSTOMER_SENT");
    },
    () => {
      toast.error(t("SEND_ORDER_AMOUNT.ERROR_MESSAGE"));
    }
  );

  const navigate = useNavigate();
  const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);

  // Вычисляем конечное время для таймера
  const endTime = order?.traderDepositedAt
    ? new Date(order.traderDepositedAt).getTime() + 15 * 60 * 1000
    : null;

  // Используем хук для таймера
  const timeLeft = useCountdownTimer(endTime);

  // Обработка истечения времени
  useEffect(() => {
    if (timeLeft === 0) {
      warningSound.play();
      setIsAlertModalOpen(true);
    }
  }, [timeLeft]);

  // Форматирование времени
  const formatTime = (time: number) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  const handleSubmitForm = () => {
    clientSentMoney({
      orderId: orderId!,
    });
  };

  const handleCloseAlertModal = () => {
    setIsAlertModalOpen(false);
    navigate(ROUTES.MAIN);
  };

  if (!order || !paymentMethod) {
    return <div>{t("LOADING")}</div>; // Лоадер, пока данные загружаются
  }

  return (
    <div className="main client-transaction-detail">
      <div className="body">
        <button className="help">
          <img className="help__icon" src="/img/svg/help.svg" alt="" />
          <p className="help__text">{t("SEND_ORDER_AMOUNT.HELP")}</p>
        </button>
        <div className="client-transaction-detail__info transaction-detail-info">
          <h1 className="transaction-detail-info__title title">
            {t("SEND_ORDER_AMOUNT.TITLE")}
          </h1>
          <p className="transaction-detail-info__text">
            {t("SEND_ORDER_AMOUNT.TRANSFER_PROMPT", {
              amount: +order.amount * exchangeRate?.exchangeRate,
              currency: order.receiverCurrency?.code,
            })}
          </p>
          <div className="transaction-detail-info__code">
            <div className="transaction-detail-info__code-value">
              {JSON.parse(paymentMethod.details || "{}")?.Number}
            </div>
            <div className="transaction-detail-info__code-actions">
              <button className="copy-btn">
                <svg className="copy-btn__icon copy-btn__icon--white">
                  <use xlinkHref="/img/sprite.svg#copy"></use>
                </svg>
              </button>
            </div>
          </div>
          <div className="transaction-detail-info__bottom">
            <div className="transaction-detail-info__bottom-item">
              <label className="transaction-detail-info__bottom-label">
                {t("SEND_ORDER_AMOUNT.RECIPIENT_NAME_LABEL")}
              </label>
              <p className="transaction-detail-info__bottom-text">
                {JSON.parse(paymentMethod.details || "{}")?.Name}{" "}
                {JSON.parse(paymentMethod.details || "{}")?.SurName}
              </p>
            </div>
          </div>
        </div>
        <div className="client-transaction-detail__summary transaction-detail-summary">
          <div className="transaction-detail-summary__amount">
            {order.amount} {order.customerCurrency.code}
          </div>
          <p className="transaction-detail-summary__text">
            {t("SEND_ORDER_AMOUNT.TRANSFER_TO_CARD")}{" "}
            <span>
              {JSON.parse(order.paymentMethod?.details || "{}")?.Number}
            </span>
          </p>
        </div>
        <div className="client-transaction-detail__time transaction-detail-time">
          <p className="transaction-detail-time__text">
            {t("SEND_ORDER_AMOUNT.TIME_REMAINING")}
          </p>
          <div className="transaction-detail-time__timer">
            {timeLeft !== null
              ? formatTime(timeLeft)
              : t("SEND_ORDER_AMOUNT.LOADING")}
          </div>
        </div>
      </div>
      <div className="footer">
        <button onClick={handleSubmitForm} className="button">
          {t("SEND_ORDER_AMOUNT.BUTTON_CONFIRM")}
        </button>
        <div className="guarantee">
          <img
            className="guarantee__icon"
            src="/img/svg/guarantee.svg"
            alt=""
          />
          <p className="guarantee__text">
            {t("SEND_ORDER_AMOUNT.GUARANTEE_TEXT")}
          </p>
        </div>
      </div>

      <Modal isOpen={isAlertModalOpen} onClose={handleCloseAlertModal}>
        <div className="order-warning-modal">
          <h1 className="order-warning-header">{t("ORDER_CANCELLED")}</h1>
          <p className="order-warning-content">
            {t("TIME_FOR_TRANSFER_EXPIRED")}{" "}
          </p>
          <div className="modal-actions">
            <button onClick={handleCloseAlertModal} className="button">
              {t("EXIT")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SendOrderAmount;
