// WebSocketProvider.tsx
import React, { useRef, useEffect } from "react";
import { io, Socket } from "socket.io-client";
import { WebSocketContext } from "../contexts/WebSocketContext";

interface WebSocketProviderProps {
  url: string;
  token: string;
  children: React.ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  url,
  token,
  children,
}) => {
  const socketRef = useRef<Socket | null>(null);
  const eventListenersRef = useRef<{
    [event: string]: ((data: any) => void)[];
  }>({});

  // Инициализация WebSocket
  useEffect(() => {
    const socket = io(url, {
      query: { token },
      transports: ["websocket"],
    });

    socketRef.current = socket;

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    socket.onAny((event, data) => {
      if (eventListenersRef.current[event]) {
        eventListenersRef.current[event].forEach((callback) => callback(data));
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [url, token]);

  // Подписка на событие
  const subscribe = (event: string, callback: (data: any) => void) => {
    if (!eventListenersRef.current[event]) {
      eventListenersRef.current[event] = [];
    }
    eventListenersRef.current[event].push(callback);
  };

  // Отписка от события
  const unsubscribe = (event: string, callback: (data: any) => void) => {
    if (eventListenersRef.current[event]) {
      eventListenersRef.current[event] = eventListenersRef.current[
        event
      ].filter((listener) => listener !== callback);
    }
  };

  // Отправка сообщения
  const emit = (event: string, data: any) => {
    if (socketRef.current) {
      socketRef.current.emit(event, data);
    } else {
      console.error("Socket is not connected.");
    }
  };

  return (
    <WebSocketContext.Provider value={{ subscribe, unsubscribe, emit }}>
      {children}
    </WebSocketContext.Provider>
  );
};
