import { useEffect, useState } from "react";
import { useLoginMutation } from "../services/auth.services";
import { setAuthToken } from "../config/authApi.config";

export const useAuth = (
  tgId: string,
  userName: string,
  referralCode: string
): { isAuthenticated: boolean; isAuthCompleted: boolean; token: string } => {
  const loginMutation = useLoginMutation((data) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("refreshToken", data.refreshToken);
    setAuthToken(data.token);
    setToken(data.token);
  });

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [isAuthCompleted, setIsAuthCompleted] = useState(false);
  const [token, setToken] = useState<string>("");

  const maxAttempts = 1;

  useEffect(() => {
    if (!tgId || !userName) {
      console.warn("Authentication skipped: tgId or userName is missing.");
      setIsAuthCompleted(true);
      return;
    }

    const authenticate = async () => {
      if (
        isAuthenticated ||
        attemptCount >= maxAttempts ||
        errorCount >= maxAttempts
      ) {
        setIsAuthCompleted(true);
        return;
      }

      try {
        await loginMutation.mutateAsync(tgId);
        setIsAuthenticated(true);
      } catch (error) {
        setErrorCount((prev) => prev + 1);
        console.error("Login failed:", error);

        if (attemptCount < maxAttempts) {
          try {
            await loginMutation.mutateAsync(tgId);
            setIsAuthenticated(true);
            setIsAuthCompleted(true);
          } catch (registerError) {
            setErrorCount((prev) => prev + 1);
            setIsAuthCompleted(true);
            console.error("Registration failed:", registerError);
          }
        } else {
          setIsAuthCompleted(true);
        }
      }
    };

    setAttemptCount((prev) => prev + 1);

    authenticate();
  }, [tgId, userName]);

  return { isAuthenticated, isAuthCompleted, token };
};
