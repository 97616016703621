import React, { useEffect } from "react";
import * as yup from "yup";
import { useTelegram } from "../../providers/TelegramProvider";
import { useNavigate } from "react-router-dom";
import { useGetCurrencies } from "../../services/currency.services";
import { useAddPaymentMethod } from "../../services/paymentMethod.services";
import toast from "react-hot-toast";
import { ROUTES } from "../../routes/routes";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../components/Form/Input";
import { ICurrency } from "../../models/currencies.services";
import Dropdown from "../../components/Form/Dropdown";
import { TFunction } from "../../models/translation";
import { useTranslation } from "react-i18next";

const NewIBAN: React.FC = () => {
  const { currencies } = useGetCurrencies();
  const { webApp } = useTelegram();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate: addPaymentMethod } = useAddPaymentMethod(
    () => {
      toast.success(t("TOAST.PAYMENT_ADDED"));
      navigate(ROUTES.MAIN);
    },
    (error) => {
      toast.error(error);
    }
  );

  const NewIBANFormSchema = yup.object().shape({
    Number: yup.string().required(t("FORM.IBAN_NUMBER.REQUIRED")),
    Currency: yup.string().required(t("FORM.CURRENCY.REQUIRED")),
    Name: yup.string().required(t("FORM.IBAN_NAME.REQUIRED")),
    SurName: yup.string().required(t("FORM.IBAN_SURNAME.REQUIRED")),
    Tag: yup.string().required(t("FORM.PAYMENT_METHOD.TAG.REQUIRED")),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(NewIBANFormSchema),
  });

  // Обработчик отправки формы
  const handleSubmitForm = (data: {
    Number: string;
    Name: string;
    SurName: string;
    Tag: string;
  }) => {
    addPaymentMethod({
      type: "IBAN",
      details: JSON.stringify({
        ...data,
      }),
    });
  };

  // Настройка кнопки "Назад" в Telegram Mini App
  useEffect(() => {
    if (!webApp) return;

    const handleBackButtonClick = () => {
      navigate(-1);
    };

    webApp.BackButton.onClick(handleBackButtonClick);
    webApp.BackButton.show();

    return () => {
      webApp.BackButton.offClick(handleBackButtonClick);
      webApp.BackButton.hide();
    };
  }, [webApp, navigate]);

  // Установка начального значения валюты
  useEffect(() => {
    if (currencies?.length > 0) {
      setValue("Currency", currencies[0]?.code);
    }
  }, [currencies, setValue]);

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="main entering-details"
    >
      <div className="body">
        <h1 className="title mb-title">{t("ADD_IBAN")}</h1>
        <div className="entering-details__form form">
          <div className="entering-details__form-items form__items">
            <Controller
              name="Number"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="input-container">
                  <Input
                    label="IBAN"
                    placeholder={t("FORM.IBAN_NUMBER.PLACEHOLDER")}
                    maxLength={19}
                    type="text"
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.Number?.message)}
                    helperText={errors.Number?.message}
                  />
                </div>
              )}
            />

            <Controller
              name="Currency"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="Currency"
                  currencies={currencies?.map((currency: any) => ({
                    id: currency.id,
                    code: currency.code,
                    name: currency.name,
                    symbol: currency.symbol,
                  }))}
                  selectedCurrency={
                    currencies?.find(
                      (currency: ICurrency) => currency?.code === value
                    ) ||
                    (currencies && currencies[0])
                  }
                  onCurrencyChange={(item) => onChange(item?.code)}
                  label={t("FORM.CURRENCY.LABEL")}
                />
              )}
            />

            <Controller
              name="Name"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  label={t("FORM.CARD_NAME.LABEL")}
                  placeholder={t("FORM.CARD_NAME.PLACEHOLDER")}
                  value={value}
                  type="text"
                  maxLength={50}
                  onChange={onChange}
                  error={Boolean(errors.Name?.message)}
                  helperText={errors.Name?.message}
                />
              )}
            />

            <Controller
              name="SurName"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  label={t("FORM.CARD_SURNAME.LABEL")}
                  placeholder={t("FORM.CARD_SURNAME.PLACEHOLDER")}
                  value={value}
                  type="text"
                  maxLength={50}
                  onChange={onChange}
                  error={Boolean(errors.SurName?.message)}
                  helperText={errors.SurName?.message}
                />
              )}
            />

            <Controller
              name="Tag"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  label={t("FORM.PAYMENT_METHOD.TAG.LABEL")}
                  placeholder={t("FORM.PAYMENT_METHOD.TAG.PLACEHOLDER")}
                  value={value}
                  type="text"
                  maxLength={50}
                  onChange={onChange}
                  error={Boolean(errors.Tag?.message)}
                  helperText={errors.Tag?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <button type="submit" className="button">
          {t("ADD")}
        </button>
      </div>
    </form>
  );
};

export default NewIBAN;
