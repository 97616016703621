import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import { useTelegram } from "../../providers/TelegramProvider";
import { useTranslation } from "react-i18next";
import { TFunction } from "../../models/translation";

const DetailsType: React.FC = () => {
  const [selectedType, setSelectedType] = useState<string>("card");
  const navigate = useNavigate();
  const { webApp } = useTelegram();
  const { t } = useTranslation();

  // Обработчик отправки формы
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedType === "iban") {
      navigate(ROUTES.NEW_IBAN);
    } else {
      navigate(ROUTES.NEW_CARD);
    }
  };

  // Настройка кнопки "Назад" в Telegram Mini App
  useEffect(() => {
    if (!webApp) return;

    const handleBackButtonClick = () => {
      navigate(-1);
    };

    webApp.BackButton.onClick(handleBackButtonClick);
    webApp.BackButton.show();

    return () => {
      webApp.BackButton.offClick(handleBackButtonClick);
      webApp.BackButton.hide();
    };
  }, [webApp, navigate]);

  return (
    <form className="details-type" onSubmit={handleSubmit}>
      <div className="body">
        <h1 className="details-type__title title">
          {t("CHOOSE_TYPE_OF_REQUISITE")}
        </h1>
        <div className="details-type__options options">
          <div className="options__item">
            <input
              id="iban"
              type="radio"
              name="details-type"
              className="options__input"
              value="iban"
              checked={selectedType === "iban"}
              onChange={() => setSelectedType("iban")} // Обновляем состояние при выборе
            />
            <label htmlFor="iban" className="options__label">
              IBAN
            </label>
          </div>
          <div className="options__item">
            <input
              id="card"
              type="radio"
              name="details-type"
              className="options__input"
              value="card"
              checked={selectedType === "card"}
              onChange={() => setSelectedType("card")} // Обновляем состояние при выборе
            />
            <label htmlFor="card" className="options__label">
              {t("CARD")}
            </label>
          </div>
        </div>
      </div>
      <div className="footer">
        <button type="submit" className="button">
          {t("CONTINUE")}
        </button>
      </div>
    </form>
  );
};

export default DetailsType;
